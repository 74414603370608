html, body
{
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Poppins', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;   
  overflow-y: hidden;
}

#root {
  /* max-width: 1280px; */
  margin: 0 auto;    
}

.MuiPopover-paper {
  max-height: 150px !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiMenu-paper::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.MuiMenu-paper::-webkit-scrollbar-track {
  background: #E2E2E2;
}

/* Handle */
.MuiMenu-paper::-webkit-scrollbar-thumb {
  background: #5C5C5C;
  border-radius: 4px;
}


.MuiAutocomplete-listbox {
  /* background: red; */
  .MuiAutocomplete-option {
    padding: 6px 16px;
    color: #232323;
    border-bottom: 1px solid #e7e7e7;
  }

  .Mui-focused {
    color: '#003E6F';
    background-color: rgba(33,150,243, 0.15) !important;
  }
}


.MuiAutocomplete-listbox::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.MuiAutocomplete-listbox::-webkit-scrollbar-track {
  background: #E2E2E2;
}

/* Handle */
.MuiAutocomplete-listbox::-webkit-scrollbar-thumb {
  background: #5C5C5C;
  border-radius: 4px;
}

/* .MuiAutocomplete-option {
  padding: 6px 16px;
  color: #232323;
  border-bottom: 1px solid #f3f3f3;
} */